import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import AppointmentService from '../../../services/Appointment.service';
import PharmacyService from '../../../services/Pharmacy.service';
import TermsService from '../../../services/Terms.service';
import UserService from '../../../services/User.service';
import type { Term } from '../../../types/terms.type';
import { Headline, Icon, Loading } from '../../atoms';
import { Header } from '../../organisms';
import classes from './Dashboard.module.scss';

const cx = classNames.bind(classes);

const appointmentsContainerClasses = cx({ AppointmentsContainer: true });
const appointmentClasses = cx({ Appointment: true });
const appointmentSelectedClasses = cx({ Appointment: true, Selected: true });
const appointmentTimeClasses = cx({ AppointmentTime: true });
const appointmentDetailsClasses = cx({ AppointmentDetails: true });
const appointmentPatientClasses = cx({ AppointmentPatient: true });
const appointmentDoctorClasses = cx({ AppointmentDoctor: true });
const appointmentChevronClasses = cx({ AppointmentChevron: true });

export function Dashboard(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [appointments, setAppointments] = useState<any[] | null>(null);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(0);
  const navigate = useNavigate();

  // Memoizing the async functions to avoid unnecessary re-renders
  const loadAppointments = useCallback(async () => {
    setLoading(true);
    try {
      const appointmentResult = await AppointmentService.getAllAppointments(16);
      setAppointments(appointmentResult.data);
    } catch (error) {
      console.error('Error loading appointments:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const loadProducts = useCallback(async () => {
    try {
      const response = await PharmacyService.getProducts();
      const products = response.data as any[];
      products.sort((a, b) => a.name.localeCompare(b.name));
      sessionStorage.setItem('products', JSON.stringify(products));
    } catch (error) {
      console.error('Error loading products:', error);
    }
  }, []);

  const loadTerms = useCallback(async () => {
    try {
      const res = await TermsService.getActiveTerms();
      if (res.error) {
        console.error('Terms could not be loaded:', res);
        alert('Terms could not be loaded.');

        return;
      }
      const terms = res.data as Term[];
      sessionStorage.setItem('terms', JSON.stringify(terms));
    } catch (error) {
      console.error('Error loading terms:', error);
    }
  }, []);

  useEffect(() => {
    if (!appointments && !loading) {
      loadAppointments();
      // Parallel data fetching for products and terms
      Promise.all([loadProducts(), loadTerms()]).catch((error) => {
        console.error('Error during parallel data loading:', error);
      });
    }
  }, [appointments, loading, loadAppointments, loadProducts, loadTerms]);

  const checkPatientData = async (appointment: any) => {
    try {
      const patient = await UserService.getPatient(appointment.patientId);
      sessionStorage.setItem('patientId', appointment.patientId.toString());
      sessionStorage.setItem('patient', JSON.stringify(patient.data));
      sessionStorage.setItem('appointmentId', selectedAppointmentId.toString());
      sessionStorage.setItem('doctorStamp', !appointment.doctorStamp ? '' : appointment.doctorStamp.toString());

      if (
        Object.prototype.hasOwnProperty.call(patient.data, 'questionnaire') &&
        Array.isArray(patient?.data?.questionnaire) &&
        patient?.data?.questionnaire.length > 0
      ) {
        navigate('/pharmacy-list', { replace: true });
      } else {
        navigate('/questionnaire/step-1', { replace: true });
      }
    } catch (error) {
      console.error('Error retrieving user data:', error);
      alert(
        `Es ein Fehler beim Abrufen der Patientendaten aufgetreten. Bitte wenden Sie sich an den Support. Patienten Id ist: ${appointment.patientId}`
      );
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Header showBackButton={false} />
      <Headline level={1}>Heutige Termine</Headline>
      {appointments !== null && sessionStorage.getItem('patientId') === null && (
        <div className={appointmentsContainerClasses}>
          {appointments.map((appointment) => (
            <div
              key={appointment.id}
              className={selectedAppointmentId === appointment.id ? appointmentSelectedClasses : appointmentClasses}
              onClick={() => {
                setSelectedAppointmentId(appointment.id);
              }}
            >
              <div className={appointmentTimeClasses}>{appointment.startTime}</div>
              <div className={appointmentDetailsClasses}>
                <div className={appointmentPatientClasses}>
                  {appointment.patientName} (geb. {appointment.patientDob})
                </div>
                <div className={appointmentDoctorClasses}>Termin bei: {appointment.doctorName}</div>
              </div>
              <div className={appointmentChevronClasses} onClick={() => checkPatientData(appointment)}>
                <Icon icon='chevron' size={45} color='#ffffff' />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
